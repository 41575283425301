import React from 'react'
import { Col, Row, Layout } from 'antd'
import styled from 'styled-components'
import { MainMenu } from '../layouts/components/MainMenu'
import { SmallVisor } from '../layouts/components/SmallVisor'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { PublicationCard } from '../layouts/components/PublicationCard'
import { Helmet } from "react-helmet";
import { useGetClimateAction } from '../services/climate-action.service'

export const ClimateAction: React.FC = () => {

  const CARequest = useGetClimateAction()
  const CAData = CARequest.data || []

  return (
    <CustomLayout>
      <Helmet>
        <title>El cambio climático: La visión del Observatorio.</title>
        <meta name="description" content="Visión del Observatorio para la Transición Energética y al Acción climática sobre el Cambio Climático" />
        <meta name="keywords" content="Cambio climático, transición ecológica, artículos, noticias, estudios, publicaciones, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <MainMenu />
      <SmallVisor pageTitle="El cambio climático" />
      <GeneralContainer>
        {
          <Row gutter={[16, 40]}>
            <Col>

              {CAData?.map(d => (
                <div className='apiHTML' key={d.id} dangerouslySetInnerHTML={{ __html: d.body as string }}></div>
              ))}

            </Col>
          </Row>
        }
      </GeneralContainer>
      <GeneralContainer>
        {
          <Row gutter={[16, 40]}>
            <Col xs={24} md={8}>
              <PublicationCard
                resourceName="Cambio Climático"
                resourceUrl="/reports/cambio-climatico-OTEA.pdf"
                imageUrl="/text-images/accion-climatica/docs-images/cambio-climatico.jpg"
              />
            </Col>
            <Col xs={24} md={8}>
              <PublicationCard
                resourceName="Acción por el Clima"
                resourceUrl="/reports/accion-por-el-clima-OTEA.pdf"
                imageUrl="/text-images/accion-climatica/docs-images/accion-clima.jpg"
              />
            </Col>
            <Col xs={24} md={8}>
              <PublicationCard
                resourceName="Transición Energética"
                resourceUrl="/reports/transicion-energetica-OTEA.pdf"
                imageUrl="/text-images/accion-climatica/docs-images/transicion-energetica.jpg"
              />
            </Col>
          </Row>
        }
      </GeneralContainer>
      <GeneralFooter />
    </CustomLayout>
  )
}

const CustomLayout = styled(Layout)`
  background: #fff;

  .image-w-note {
    float: right;
    margin-left: 50px;
    text-align: right;

    img {
      width: 450px;
      display: block;
    }
  }
`
