import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Col, Dropdown, Menu, Radio, Row, Select, Slider, Tabs } from 'antd'
import { CaretRightOutlined, DownloadOutlined, DownOutlined, SearchOutlined, FileExcelOutlined, FileImageOutlined, PauseOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting'
import HighMaps from 'highcharts/modules/map'
import useFetch from 'use-http'
import styled from 'styled-components'
import { spainMap } from '../config/spain-map'
import { colors, getAbsoluteServerUrl } from '../config/config'
import { ChartData, chartTypeMap, chartTypes, ChartTypesEnum, FinalSeries, getMax, getMin, getSeries, isRelativeType, isSingleYearType, Series, transformRawIndicatorData, transformSeries, UnitsEnum, useGetIndicators, WaterfallData } from '../config/indicators-model'
import { IndicatorTable } from '../pages/IndicatorTable'
import { convert, convertUsingMultiplier } from '../config/conversions'
import { getMapChartOptions } from '../config/indicators-chart-options'
import { ChartMeta, ChartMetaSource } from './ChartMeta'

import { Helmet } from 'react-helmet'

const { TabPane } = Tabs

HighchartsExporting(Highcharts)
HighchartsOfflineExporting(Highcharts)
HighMaps(Highcharts)
Highcharts.maps['countries/es/es-all'] = spainMap
Highcharts.setOptions({
  chart: {
    style: {
      fontSize: '18px',
    },
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '15px',
        textOverflow: 'none',
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        fontSize: '15px',
      },
    },
  },
  tooltip: {
    style: {
      fontSize: '15px',
    },
  },
  colors: colors.alternatives,
  lang: {
    resetZoom: 'Restablecer Zoom',
    thousandsSep: '.',
    decimalPoint: ',',
  },
})

export type SelectedSeries = Series & {
  name: string
  dashed: boolean
  excludedTypes: ChartTypesEnum[]
}

type Props = {
  chart: string
  subChart: string
  defaultChartType?: ChartTypesEnum
  defaultYear?: number
  disableSeries?: string[]
  hideMeta?: boolean
}

export const Chart: React.FC<Props> = ({ chart, subChart, defaultChartType, disableSeries, defaultYear, hideMeta }) => {
  const [selectedYear, setSelectedYear] = useState<number | undefined>(defaultYear)
  const [selectedChartType, setSelectedChartType] = useState(defaultChartType)
  const [selectedUnit, setSelectedUnit] = useState<UnitsEnum>()
  const [highchartsDelay, setHighchartsDelay] = useState(0)
  const chartObject = useRef<Highcharts.Chart>()
  const [playStatus, setPlayStatus] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const sliderContainerRef = useRef<any>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setHighchartsDelay(1)
    }, 1500)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (selectedYear && playStatus) {
      const timer = setTimeout(() => {
        const nextYearIndex = availableYearsWithoutGaps.findIndex(y => selectedYear === y) + 1
        setSelectedYear(availableYearsWithoutGaps[nextYearIndex])
      }, 750)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playStatus, selectedYear])

  useEffect(() => {
    setPlayStatus(false)
    setSelectedYear(defaultYear)
    setSelectedChartType(defaultChartType)
    setSelectedUnit(undefined)
  }, [chart, defaultYear, defaultChartType, subChart])

  useEffect(() => {
    // window.dispatchEvent(new Event('resize'))
  }, [fullScreen])

  const fetchUrl = getAbsoluteServerUrl(`/indicators/${subChart}`)
  const dataRequest = useFetch<ChartData>(fetchUrl, { responseType: 'json' }, [fetchUrl])
  const chartData = dataRequest.data ? transformRawIndicatorData(dataRequest.data) : undefined

  const indicatorsRequest = useGetIndicators()
  const indicators = useMemo(() => {
    return indicatorsRequest.data || []
  }, [indicatorsRequest.data])

  if (!indicators.length) {
    return <></>
  }

  if (!chartData || !chartData[subChart]) {
    return <></>
  }

  const selectedChart = indicators.find(c => c.code === chart)

  if (!selectedChart) {
    throw Error('Page not found')
  }

  const selectedSubChart = selectedChart.indicators.find(sc => sc.code === subChart)

  if (!selectedSubChart) {
    throw Error('Page not found')
  }

  const autoDefaultChartType = selectedSubChart.defaultChart || selectedSubChart.chartTypes[0]
  if (!selectedChartType || !selectedSubChart.chartTypes.includes(selectedChartType)) {
    setSelectedChartType(autoDefaultChartType)
    return <></>
  }

  // TODO: Better definition of date chart
  const isDateChart = selectedSubChart.chartTypes[0] !== ChartTypesEnum.Waterfall

  const finalSelectedUnit = selectedUnit || selectedSubChart?.units[0]

  const selectedSeries: SelectedSeries[] =
    chartData &&
    chartData[subChart]
      .map(series => {
        const seriesDef = getSeries(selectedSubChart, series.key)
        return {
          ...series,
          values: series.values.map(point => {
            if (point.value === null) {
              return point
            }

            if (finalSelectedUnit && finalSelectedUnit !== series.units && selectedSubChart.units.length > 1) {
              if (selectedSubChart.multiplier1to2) {
                return {
                  ...point,
                  value: convertUsingMultiplier(
                    series.units,
                    finalSelectedUnit,
                    selectedSubChart.units,
                    selectedSubChart.multiplier1to2,
                    selectedSubChart.multiplier1to3,
                    point.value,
                  ),
                }
              }

              return {
                ...point,
                value: convert(series.units as UnitsEnum, finalSelectedUnit as UnitsEnum, point.value),
              }
            }
            return point
          }),
          name: seriesDef.name,
          excludedTypes: seriesDef.excludedTypes,
          dashed: seriesDef.dashed,
        }
      })
      .filter(s => !s.excludedTypes.includes(selectedChartType))

  const maxY = getMax(selectedSeries, selectedChartType)
  const minY = getMin(selectedSeries)
  const availableCategories = selectedSeries ? selectedSeries[0].values.map(point => String(point.year)) : []

  const availableYears = availableCategories.map(y => Number(y))
  const autoDefaultYear =
    selectedSubChart.defaultYear === 'last'
      ? availableYears[availableYears.length - 1]
      : selectedSubChart.defaultYear
        ? Number(selectedSubChart.defaultYear)
        : availableYears[0]

  if (!selectedYear || !availableYears.includes(selectedYear)) {
    setSelectedYear(playStatus ? availableYears[availableYears.length - 1] : autoDefaultYear)
    setPlayStatus(false)
    return <></>
  }

  const availableYearsWithoutGaps = availableYears.filter((y, i) => {
    return selectedSeries.filter(s => s.values[i].value !== null).length
  })

  const selectedCategoryIndex = isDateChart
    ? availableCategories.findIndex(c => {
      return Number(c) === selectedYear
    })
    : 0

  if (selectedCategoryIndex < 0) {
    throw Error(`Unable to select year ${selectedYear}`)
  }

  const filteredCategories = [availableCategories[selectedCategoryIndex]]
  const categories = isSingleYearType(selectedChartType) ? filteredCategories : availableCategories

  // TODO: Try to match year instead of just using the index
  const sumSeries = selectedSeries[0].values.map((_value, index) => {
    return selectedSeries
      .map(series => (index < series.values.length ? series.values[index].value : 0))
      .reduce((prev, current) => prev + current, 0)
  })

  const allSeries: FinalSeries[] = transformSeries(
    selectedSeries
      ? selectedSeries.map(item => {
        const selectedValues = isSingleYearType(selectedChartType)
          ? [item.values[selectedCategoryIndex]]
          : selectedChartType === ChartTypesEnum.Percentage
            ? item.values.map((v, index) => ({
              ...v,
              value: (v.value / sumSeries[index]) * 100,
            }))
            : item.values
        return {
          name: item.name,
          ...(item.dashed ? { dashStyle: 'dash' } : {}),
          data: selectedValues.map(point => point.value),
          visible: !disableSeries || !disableSeries.length || !disableSeries.includes(item.key),
        }
      })
      : [],
    selectedChartType,
  )

  const series =
    selectedChartType === ChartTypesEnum.Pie
      ? [{ name: selectedYear, data: allSeries.map(s => ({ name: s.name, y: s.data[0] })) }]
      : selectedChartType === ChartTypesEnum.Waterfall
        ? [
          {
            data: allSeries.reduce((prev: WaterfallData[], current, index) => {
              const wfData = current.data[0] as WaterfallData
              const lastElem = index === allSeries.length - 1
              return [
                ...prev,
                {
                  ...wfData,
                  name: `${selectedSeries[index].name}${lastElem ? ` ${selectedYear}` : ''}`,
                  isSum: lastElem,
                  color: colors.alternatives[index === 0 ? 0 : lastElem ? 1 : 2],
                },
              ]
            }, []),
          },
        ]
        : allSeries

  const displayUnit = isRelativeType(selectedChartType) ? '%' : selectedUnit || selectedSubChart.units[0]

  const longTitle = selectedSubChart.longTitle || selectedSubChart.title

  const options =
    selectedChartType === ChartTypesEnum.Map
      ? getMapChartOptions(series as unknown as FinalSeries[], longTitle, selectedYear, displayUnit, maxY, fullScreen)
      : {
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                chart: {
                  height: '400',
                },
                yAxis: {
                  title: {
                    text: undefined,
                  },
                  offset: 0,
                  labels: {
                    style: {
                      fontSize: '11px',
                    },
                  },
                },
                xAxis: {
                  offset: 0,
                  labels: {
                    style: {
                      fontSize: '11px',
                    },
                  },
                },
                title: {
                  style: {
                    fontSize: '11px',
                  },
                },
                legend: {
                  itemStyle: {
                    fontSize: '10px',
                  },
                },
              },
            },
          ],
        },
        chart: {
          height: fullScreen ? '45%' : '600',
          type: chartTypeMap(selectedChartType),
          zoomType: 'xy',
          marginTop: 60,
          map: 'countries/es/es-all',
        },
        exporting: {
          enabled: false,
          sourceWidth: 1280,
          sourceHeight: 720,
          chartOptions: {
            legend: {
              maxHeight: null,
            },
          },
        },
        title: {
          y: 25,
          text: `${longTitle}${isSingleYearType(selectedChartType)
            ? `${selectedChartType === ChartTypesEnum.Waterfall ? ` ${selectedSubChart.availableRange[0]}` : ''
            } - ${selectedYear}`
            : ''
            }`,
          widthAdjust: -90,
        },
        credits: false,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ` ${displayUnit}`,
          pointFormat:
            selectedChartType === ChartTypesEnum.Pie
              ? '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b> ({point.percentage:.1f} %)<br/>'
              : selectedChartType === ChartTypesEnum.Waterfall
                ? `<span style="color:{point.color}">\u25CF</span> ${selectedYear}: <b>{point.y}</b><br/>`
                : '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
        },
        plotOptions: {
          column: {
            stacking: selectedChartType === ChartTypesEnum.ColumnStacked ? 'normal' : undefined,
          },
          line: {
            lineWidth: 4,
          },
          spline: {
            lineWidth: 4,
            states: {
              hover: {
                lineWidth: 5,
              },
            },
            marker: {
              enabled: false,
            },
          },
          area: {
            stacking: 'normal',
          },
        },
        ...(selectedChartType !== ChartTypesEnum.Waterfall
          ? {
            xAxis: {
              categories,
            },
          }
          : { xAxis: { type: 'category', categories: null } }),
        yAxis: {
          visible: selectedChartType !== ChartTypesEnum.Pie,
          title: {
            text: displayUnit,
          },
          min: !selectedSubChart.startFromZero ? null : minY >= 0 ? 0 : null,
          minorGridLineWidth: 0,
          gridLineWidth: selectedChartType === ChartTypesEnum.Pie ? 0 : 1,
          labels: {
            formatter:
              isRelativeType(selectedChartType) || (selectedUnit as string) === '%'
                ? function () {
                  // @ts-ignore
                  const self = this as any

                  if (self.value > 100) {
                    return ''
                  }

                  return Highcharts.numberFormat(self.value, 0)
                }
                : null,
          },
          // alternateGridColor: '#f8fcf8',
          /*...(isRelativeType(selectedChartType)
            ? {
                labels: {
                  format: '{value:.0f}%',
                },
              }
            : { labels: { format: undefined } }),*/
          ...(isSingleYearType(selectedChartType)
            ? { max: maxY }
            : {
              max: null,
            }),
        },
        legend: {
          enabled: selectedChartType !== ChartTypesEnum.Waterfall,
          navigation: { enabled: true },
          itemWidth: 300,
          itemStyle: {
            textOverflow: 'undefined',
          },
          maxHeight: 100,
        },
        series,
      }

  const marks = availableYearsWithoutGaps
    .map((y, index) => {
      const approxPixelsPerStep = sliderContainerRef.current?.clientWidth / availableYearsWithoutGaps.length
      if (Number.isNaN(approxPixelsPerStep)) {
        return { y, show: true }
      }

      const stepsToShow = Math.ceil(70 / approxPixelsPerStep)

      return { y, show: index % stepsToShow === 0 }
    })
    .reduce((prev, current) => {
      return {
        ...prev,
        [current.y]: current.show ? String(current.y) : '',
      }
    }, {})

  const allSources: ChartMetaSource = {
    global: selectedSubChart.sources,
    specific: selectedSubChart.series
      .map(s => ({ name: s.name, sources: s.sources, derivation: s.derivation }))
      .filter(s => s.sources.length > 0),
  }

  return (

    <div ref={sliderContainerRef}>

      <Helmet>
        <title>{selectedSubChart.longTitle}</title>
        <meta name="description" content={selectedSubChart.description.substring(0, 170)} />
        <meta
          name="keywords"
          content="Serie temporal, periodicidad, unidades, fuentes, descripción"
        />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
      </Helmet>

      <NoPaddingCard
        bordered={false}
        style={{ width: '100%' }}
        className={fullScreen ? 'full-screen' : ''}
        title={
          <Row>
            <Col xs={24} lg={12} style={{ margin: '0 0 10px' }}>
              <Dropdown
                overlay={
                  <Menu onClick={e => setSelectedChartType(e.key as ChartTypesEnum)}>
                    {selectedSubChart.chartTypes.map((c: ChartTypesEnum) => (
                      <Menu.Item key={c}>{chartTypes[c].name}</Menu.Item>
                    ))}
                  </Menu>
                }>
                <Button>
                  {chartTypes[selectedChartType].name} <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            {isRelativeType(selectedChartType) ? (
              <Col xs={24} lg={12} className="right-db-controls" />
            ) : (
              <Col xs={24} lg={12} className="right-db-controls">
                {selectedSubChart?.units.length > 1 ? (
                  <Radio.Group
                    value={finalSelectedUnit}
                    onChange={e => {
                      setSelectedUnit(e.target.value)
                    }}>
                    {selectedSubChart?.units.map(unit => (
                      <Radio.Button key={unit} value={unit}>
                        {unit}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                ) : (
                  <>{selectedSubChart.units ? `Unidades: ${selectedSubChart.units[0]}` : ''}</>
                )}
              </Col>
            )}
            {isSingleYearType(selectedChartType) ? (
              <Col xs={24} lg={12} style={{ margin: '0 0 10px' }}>
                <Select style={{ width: '100%' }} value={selectedYear} onChange={val => setSelectedYear(val)}>
                  {availableYearsWithoutGaps
                    .slice()
                    .reverse()
                    .map(c => (
                      <Select.Option value={Number(c)}>{c}</Select.Option>
                    ))}
                </Select>
              </Col>
            ) : (
              <Col xs={24} lg={12} />
            )}
            <Col xs={24} lg={12} className="doc-db-controls">
              {/*<Button
              onClick={() => {
                const downloadData = [
                  ['nombre', ...selectedSeries[0].values.map(v => v.year)].join(';'),
                  ...selectedSeries.map(s => [`"${s.name}"`, ...s.values.map(v => `"${v.value}"`)].join(';')),
                ].join('\n')

                const blob = new Blob([downloadData], { type: 'text/csv;charset=utf-8' })
                saveAs(blob, `${selectedSubChart.exportFileName || selectedSubChart.longTitle}.csv`)
              }}>
              <FileExcelOutlined />
              <DownloadOutlined />
            </Button>*/}
              <Button
                href={getAbsoluteServerUrl(
                  `/downloads/charts/${selectedChart.code[0]}/${selectedChart.code}/${selectedSubChart.code}.xlsx`,
                )}
                target="_blank"
                rel="noopener noreferrer">
                {' '}
                <FileExcelOutlined />
                <DownloadOutlined />
              </Button>{' '}
              <Button
                onClick={() => {
                  chartObject.current && chartObject.current.exportChartLocal()
                }}>
                <FileImageOutlined />
                <DownloadOutlined />
              </Button>{' '}
              <Button
                onClick={() => {
                  setHighchartsDelay(0)
                  setTimeout(() => setHighchartsDelay(1), 500)
                  setFullScreen(!fullScreen)
                }}>
                {fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
              </Button>
            </Col>
          </Row>
        }>


        {!dataRequest.loading && chartData && highchartsDelay > 0 ? (
          selectedChartType === ChartTypesEnum.List ? (
            <IndicatorTable series={selectedSeries} />
          ) : (
            <ChartContainer>
              {selectedChartType === ChartTypesEnum.Map ? (
                <>
                  {'' /* don't remove this or the chart map will stop working */}
                  <HighchartsReact
                    constructorType={'mapChart'}
                    options={options}
                    callback={(c: Highcharts.Chart) => {
                      if (!chartObject.current) {
                        chartObject.current = c
                      }
                      c.renderer.image('/logos/chart-icon-100.png', c.chartWidth - 40 - 10, 5, 40, 44).add()
                    }}
                  />
                </>
              ) : (
                <>
                  {fullScreen ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                      callback={(c: Highcharts.Chart) => {
                        if (!chartObject.current) {
                          chartObject.current = c
                        }
                        c.renderer.image('/logos/chart-icon-100.png', c.chartWidth - 80 - 10, 5, 40, 44).add()
                      }}
                    />
                  ) : (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                      callback={(c: Highcharts.Chart) => {
                        if (!chartObject.current) {
                          chartObject.current = c
                        }
                        c.renderer.image('/logos/chart-icon-100.png', c.chartWidth - 40 - 10, 5, 40, 44).add()
                      }}
                    />
                  )}
                </>
              )}
            </ChartContainer>
          )
        ) : (
          ''
        )}
        {isSingleYearType(selectedChartType) ? (
          <Row>
            <Col xs={4} sm={2} md={1} style={{ textAlign: 'left' }}>
              <Button
                style={{ marginRight: '15px' }}
                onClick={() => {
                  if (playStatus) {
                    setPlayStatus(false)
                    return
                  }
                  const nextYearIndex = availableYearsWithoutGaps.findIndex(y => selectedYear === y) + 1
                  setPlayStatus(true)
                  setSelectedYear(
                    availableYearsWithoutGaps[availableYearsWithoutGaps.length - 1] === selectedYear
                      ? availableYearsWithoutGaps[0]
                      : availableYearsWithoutGaps[nextYearIndex],
                  )
                }}>
                {playStatus ? <PauseOutlined /> : <CaretRightOutlined />}
              </Button>
            </Col>
            <Col xs={20} sm={22} md={23}>
              <Slider
                min={availableYearsWithoutGaps[0]}
                max={availableYearsWithoutGaps[availableYearsWithoutGaps.length - 1]}
                marks={marks}
                step={null}
                value={selectedYear}
                onChange={(y: number) => {
                  setSelectedYear(y)
                }}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}

        {!hideMeta && <ChartMeta
          units={selectedSubChart.units}
          timeRange={selectedSubChart.availableRange}
          periodicity={selectedSubChart.periodicity}
          sources={allSources}
          description={selectedSubChart.description}
          chart={selectedSubChart}
        />}
      </NoPaddingCard>
    </div>
  )
}

const ChartContainer = styled.div`
  padding: 30px 20px 30px 10px;
  @media (min-width: 1024px) {
    padding: 40px 50px 40px 40px;
  }
`

const NoPaddingCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }

  .ant-tabs-tabpane > div {
    padding: 0;
  }
`
